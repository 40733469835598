/*===============================
  About Me Section Card
===============================*/

.about-us-section-card {
    .about-us-card {
        border-radius: 20px;
        background: var(--color-gray-2);
        padding: 40px 30px;
        height: 100%;
        @media #{$large-mobile} {
            padding: 15px;
        }
        .card-head {
            display: flex;
            align-items: center;
            gap: 14px;
            .logo-img {
                width: 45px;
                height: 45px;
                background: var(--color-primary);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                min-width: 45px;
                i {
                    font-size: 18px;
                    color: var(--color-white);
                }
            }
            .card-title {
                color: var(--color-heading);
                font-family: var(--font-primary);
                font-size: 22px;
                font-weight: 700;
                line-height: 30px;
                margin-bottom: 0;
            }
        }
        .card-para {
            color: var(--color-gray);
            font-family: var(--font-secondary);
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            margin-top: 11px;
        }
    }
}

/*===============================
  About Me Left Content Wrap
  ===============================*/
.about-us-left-content-wrap {
    position: relative;
    z-index: 1;

    @media (min-width: 576px) {
        padding-left: 110px;
        padding-right: 72px;
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @media #{$sm-layout} {
        padding: 0;
    }
    &.bg-vactor-one {
        &::after {
            content: " ";
            position: absolute;
            left: 0;
            top: 197px;
            background: linear-gradient(0deg, rgba(255, 1, 79, 0.00) 15.49%, var(--color-primary) 100%);
            filter: blur(2px);
            border-radius: 100%;
            width: 410px;
            height: 410px;
            z-index: -1;
            @media #{$md-layout} {
                width: 250px;
                height: 250px;
            }
            @media #{$sm-layout} {
                width: 200px;
                height: 200px;
            }
        }
    }

    &.bg-vactor-two {
        &::after {
            content: " ";
            position: absolute;
            left: 0;
            top: 197px;
            background: linear-gradient(0deg, rgba(255, 1, 79, 0.00) 15.49%, #FDC448 100%);
            filter: blur(2px);
            border-radius: 100%;
            width: 410px;
            height: 410px;
            z-index: -1;
        }
    }
    .years-of-experience-card {
        border-radius: 20px;
        border: 0.5px solid rgba(255, 255, 255, 0.05);
        background: rgba(22, 22, 22, 0.30);
        box-shadow: 15px 26.023px 50px 0px rgba(253, 196, 72, 0.04);
        backdrop-filter: blur(35px);
        padding: 64px 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media #{$md-layout} {
            padding: 40px 30px;
        }
        @media #{$sm-layout} {
            padding: 40px 30px;
        }
        .counter-main {
            width: 150px;
            height: 150px;
            background: #060606;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            margin-bottom: 0;
            min-width: 100px;
            .card-title {
                color: var(--color-primary);
                font-family: var(--font-primary);
                font-size: 68px;
                font-weight: 700;
                line-height: 78px;
            }
        }
        .card-title {
            margin-bottom: .5rem;
            font-size: 80px;
            @media #{$md-layout} {
                font-size: 60px;
            }
            @media #{$sm-layout} {
                font-size: 60px;
            }
        }
        .card-para {
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 48px;
            font-weight: 700;
            line-height: 60px;
            text-transform: capitalize;
            text-align: center;
            margin-top: 20px;
            @media #{$sm-layout} {
                font-size: 32px;
                line-height: 42px;
                margin-top: 10px;
            }
        }
    }

    .design-card {
        display: flex;
        align-items: center;
        gap: 30px;
        border-radius: 20px;
        border: 0.5px solid rgba(255, 255, 255, 0.05);
        background: rgba(22, 22, 22, 0.30);
        box-shadow: 15px 26.023px 50px 0px rgba(253, 196, 72, 0.04);
        backdrop-filter: blur(35px);
        padding: 30px;
        margin-top: 30px;
        @media #{$sm-layout} {
            gap: 15px;
            padding: 30px 15px;
        }
        .design-card-img {
            width: 100px;
            height: 100px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid var(--color-border);
            @media #{$sm-layout} {
                width: 60px;
                height: 60px;
                min-width: 60px;
            }
            i {
                color: var(--color-primary);
                font-size: 40px;
                @media #{$sm-layout} {
                    font-size: 26px;
                }
            }
            img {
                @media #{$sm-layout} {
                    max-height: 30px;
                }
            }
        }
        .card-info{
            p{
                color: var(--color-heading);
            }
        }
    }
}


