body {
	&.color-primary-2nd {
		--color-primary: var(--color-primary-2nd); 
	}
	&.color-blue{
		--color-primary: #5237f9;
	}
	&.primary-yellow{
		--color-primary: #FFB400;
		.bg_image--8 {
			background-image: url(../images/bg/04.webp);
		}
	}
	&.color-pink{
		--color-primary: #EA4C89;
		.bg_image--2 {
			background-image: url(../images/bg/bg-image-13.jpg);
		}
	}
	&.color-primary-3rd {
		--color-primary: var(--color-primary-3rd); 
		--color-primary-alt: var(--color-primary-3rd); 
		.services-widget.v2 .active-bg {
			background: #ffc10714;
		}
	}

	&.color-primary-4th {
		--color-primary: var(--color-primary-4th); 
	}

	&.tmp-white-version {
		--color-heading: #141414;
		--color-gray: #5d6570;
		--color-body: #2a2d31;
		--color-gray-2: #F4F4F4;
		--color-border: #D9DDE0;
		background: #ffffff;
		.bg_modern-banner-bg {
			background-image: url(../images/bg/06.webp);
		}
		.bg_modern-banner-bg::after{
			display: none;
		}
		.banner-inner-modern-content .right-content p.disc {
			color: #141414;
		}
		&.box-body .main-wrapper-inner {
			background: #ffffff;
		}
		.newsletter-form-1 input {
			color: #141414;
		}
		.tmp-banner-one-area {
			background-image: url(../images/bg/bg-image-12.jpg);
			background-color: #EDEDED;
		}
		&.demo-model .tmp-banner-one-area.style-2 {
			background-image: url(../images/bg/03.webp);
		}
		.rpp-banner-five-area {
			background-color: #EDEDED;
		}
		.model-style-banner.with-instructor .banner-model-15 .title{
			color: #fff;
		}

		.services-widget.v1 .service-item .my-skill-card .card-para {
			color: var(--color-heading);
		}
		.services-widget.v1 .service-item.mleave .my-skill-card .card-para {
			color: var(--color-gray);
		}
		&.demo-model .banner-model-15 .title{
			color: #fff;
		}
		.newsletter-form-1 input::placeholder,
		.newsletter-form-1 textarea::placeholder,
		input::placeholder,
		textarea::placeholder {
			color: var(--color-gray);
		}
		.social-link a {
			background: rgb(10 10 10 / 6%);
		}
		.custom-title span img {
			filter: brightness(0.5);
		}

		.rpp-banner-two-area .banner-shape-two img {
			opacity: 0.1;
		}

		.banner-two-main-wrapper .banner-right-content .main-img::after{
			display: none;
		}

		.banner-two-main-wrapper .banner-right-content .main-img .logo-under-img-wrap .logo-under-img img {
			filter: invert(1);
		}
		
		.right-bg-text-para,
		.left-bg-text-para {
			filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
		}
		.about-us-left-content-wrap .years-of-experience-card,
		.about-us-left-content-wrap .design-card {
			background: rgba(255, 255, 255, 0.3);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		}

		.client-testimonial-swiper .swiper::before {
			background: linear-gradient(90deg, #f4f4f4b0 0%, rgb(235 231 231 / 5%) 100%);
		}
		.client-testimonial-swiper .swiper::after {
			background: linear-gradient(90deg, #f4f4f4b0 0%, rgb(235 231 231 / 5%) 100%);
		}
		
		.client-testimonial-card-wrap .quat-logo img,
		.my-skill-card .card-icon img {
			filter: inherit;
		}

		.banner-three-main-wrapper .bg-benner-img-three::before {
			background: linear-gradient(180deg, rgba(7, 7, 7, 0) 27.58%, #d3b0b0 80.06%);
		}
		svg.radial-progress text {
			fill: #141414;
		}
		.service-item.current .my-expertise-card-wrap .expertise-card-left .expertise-card-logo img {
			filter: brightness(1);
		}
		.service-item .my-expertise-card-wrap .expertise-card-left .expertise-card-logo img {
			filter: brightness(0.5);
		}
		#side-hide,
		.breadcrumb-area,
		.tmp-sidebar-area .inner .top-area {
			background: #EDEDED;
		}
		#side-hide .top-area,
		.contact-info {
			background: #ffffff;
		}
		.tmp-round-action-btn {
			background: rgba(10, 10, 10, 0.06);
			color: var(--color-heading);
		}
		.tmp-round-action-btn:hover {
			color: var(--color-white);
		}
		.tmp-arrow-icon-btn:hover i.tmp-icon-bottom {
			color: var(--color-white);
		}
		.signle-side-bar .tags-wrapper .tag-link {
			background: rgba(10, 10, 10, 0.06);
		}
		.signle-side-bar .tags-wrapper .tag-link:hover {
			color: var(--color-white);
			background: var(--color-primary) !important;
		}

		.logo img.logo-white {
			display: block;
		}
		
		.logo img.logo-dark {
			display: none;
		}

		.tmp-contact-about-inner,
		.tab-navigation-button ,
		.tmp-all-tab-content {
			background: #ffffff;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
		}

		

		.tmp-contact-about-inner .info-box {
			background: var(--color-white);
		}

		.tmp-banner-one-area.style-2 {
			background-image: url(../images/bg/bg-image-9.jpg);
		}

		.bg_image--8 {
			background-image: url(../images/bg/bg-image-8.jpg);
		}
		&.primary-yellow .bg_image--8 {
			background-image: url(../images/bg/04.webp);
		}
		.tmp-banner-one-area.style-4 {
			background-image: url(../images/bg/bg-image-11.jpg);
		}

		.tmp-popup-mobile-menu .header-top .close-menu .close-button {
			background: rgba(10, 10, 10, 0.06);
			color: var(--color-heading);
		}
		
		.newslatter .newsletter-form-1 .form-icon{
			i{
				color: #fff;
			}
		}
		.banner-two-main-wrapper .banner-right-content .main-img .banner-big-text-2{
			-webkit-text-stroke: 1px #fff;
		}

		

		&.personal-demo-left-fixed{
			.skill-style-border-card img{
				filter: brightness(0) saturate(100%)
			}
			.pricing-table-col-2 .pricing-table-header h4,
			.pricing-table-col-2 .pricing-table-header .top p,
			.pricing-table-col-2 .feature-lists li,
			.pricing-table-col-2 .pricing-table-header h3 span,
			.pricing-table-items .info,
			.experience-style-list .experience-list .resume-single-list:hover .inner .heading .title h4{
				color: var(--color-heading);
			}
			.tmp-footer-area-style-net .bg_patern-1 {
				background-color: rgb(255 255 255 / 56%) !important;
			}
			.tmp-footer-area-style-net .bg_patern-1::after {
				opacity: .1;
			}
			.contact-area-left-portfolio-fixed input, .contact-area-left-portfolio-fixed textarea {
				background: var(--color-gray-2);
				border: 1px solid #e4e4e4;
			}
			.experience-style-list .experience-list .resume-single-list {
				background: #f4f4f4;
			}
			.experience-style-list .experience-list .resume-single-list .inner::after {
				border: 5px solid #e4e4e4;
				background: var(--color-primary);
			}
			.experience-style-list .experience-list .resume-single-list .inner .heading .date-of-time span{
				background: var(--color-primary);
			}
			.experience-style-list .experience-list .resume-single-list .inner .heading .title span {
				color: #333333;
				font-weight: 500;
			}
			.experience-style-list .experience-list .resume-single-list .inner::before {
				background: #e4e4e4;
			}
			.experience-style-list .experience-list {
				border-left: 5px solid #e4e4e4;
			}
			.progress {
				background-color: #e4e4e4;
			}
			.personal-portfolio-right-inner-wrapper .counter-style-2.border-style.small-radious .count-number {
				background-color: #ffffff;
				color: #141414 !important;
				span{
					color: #141414 !important;
				}
			}
			.experience-style-list .experience-list .resume-single-list .inner .heading {
				border-bottom: 1px solid #e8e8e8;
			}
			.profile-image-1-personal-left-fixed::after {
				background: linear-gradient(0deg, rgb(255 255 255) 0%, rgba(0, 0, 0, 0) 100%);
				z-index: 0;
			}
			.profile-image-1-personal-left-fixed .social-default li a {
				background-color: #ffffff;
				i{
					color: var(--color-primary);
				}
				&:hover{
					i{
						color: #fff;
					}
				}
			}
			.copyright-style-net .copyright-center ul li a{
				background: var(--color-primary);
			}
			
		}
		.banner-twelve-area.with-bg-image .banner-one-main-wrapper .inner .title{
			color: #fff;
		}
		.banner-twelve-area.with-bg-image .social-share-style-1 a{
			background: #fff;
		}

		.banner-twelve-area.with-bg-image {
			background-image: url(../images/bg/02.webp) !important;
		}
		.header-left-sticky .inner-wrapper::after {
			background: transparent;
		}
	}
}
.tmp-white-version{
	.tmp-right-demo .demo-button::before {
		content: "";
		background: linear-gradient(-45deg, #ee7752, #ff004b, #ff004b, #d52323);
		background-size: 400% 400%;
		animation: gradient 4s ease infinite;
		opacity: 1;
		backdrop-filter: blur(21px);
	}
	.tmp-right-demo *{
		color: #fff !important;
	}
	&.index-8 .tmp-contact-about-inner::after{
		display: none !important;
	}
}

.tmp-white-version{
	.tmp-banner-one-area.style-3 .thumbnail-author img, .tmp-banner-one-area.style-4 .thumbnail-author img {
		border: 25px solid #ebebeb;
	}
	// .scrollToTop {
	// 	background-color: #f4f4f4;
	// }
	// .scrollToTop .arrowUp i {
	// 	color: #141414;
	// }
}

.index-ten{
	.tmp-contact-about-inner{
		flex-direction: column;
	}
	.tmp-contact-about-inner .thumbnail img{
		@media #{$sm-layout} {
			max-width: 100%;
			margin-bottom: 30px;
		}
	}
}

.tmp-white-version{
	.year-of-expariance-wrapper.bg-blur-style-one::after{
		display: none;
	}
	.services-widget.v1 .my-skill-card .card-icon {
		border: 2px solid #141414;
	}
	.color-primary-2nd .services-widget.v1 .my-skill-card .card-icon i {
		color: var(--color-heading);
	}
	.about-us-left-content-wrap .design-card .design-card-img {
		border: 2px solid var(--color-primary);
	}
	.color-primary-2nd  .services-widget.v2 .active-bg {
		background: var(--color-primary);
	}
	.color-primary-2nd  .my-expertise-card-wrap .expertise-card-left .expertise-card-logo img {
		filter: brightness(0) saturate(100%) !important;
	}
	.my-expertise-card-wrap .expertise-card-left .expertise-card-logo {
		border: 2px solid #141414;
	}
	.rpp-banner-two-area::before {
		display: none;
	}
	.rpp-banner-two-area::after {
		display: none;
	}
	.banner-three-main-wrapper .bg-benner-img-three::before{
		display: none;
	}
	.services-widget.v4 .active-bg {
		background: rgb(253 196 72);
	}
	.tmp-banner-one-area.style-4 .galaxy {
		opacity: 0.07;
	}


	.tmponhover {
		box-shadow: 2px 2px var(--color-primary);
		transition: .3s;
		&:hover{
			box-shadow: none;
		}
	}
	.tmp-banner-area-full-width-modern .social-area-wrapper-varticle ul li a {
		background: #0d6efd;
	}
	// .bg_modern-banner-bg::after {
	// 	background: radial-gradient(circle, rgba(2, 0, 36, 0) 0%, rgb(255 255 255 / 76%), #ffffff);
	// }
}
body.tmp-white-version.color-primary-3rd .services-widget.v2 .active-bg {
	background: rgb(255 193 7);
}

.demo-modal-area .wrapper .tmp-modal-inner .demo-top .subtitle{
	color: #c2c2c2 !important;
}
.active-light.demo-modal-area .wrapper .tmp-modal-inner .demo-top .subtitle{
	color: #3d3d3d !important;
}