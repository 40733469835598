body.loaded {
	overflow: hidden !important;
	height: 100% !important;
  }
  
  .loader-wrap {
	position: fixed;
	z-index: 10;
	height: 100vh;
	width: 100%;
	left: 0;
	top: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: transparent;
	z-index: 99999999999999;
  }
  
  .loader-wrap svg {
	position: absolute;
	top: 0;
	width: 100vw;
	height: 110vh;
	fill: #1a1a1a;
  }
  
  .loader-wrap .loader-wrap-heading .load-text {
	font-size: 20px;
	font-weight: 200;
	letter-spacing: 15px;
	text-transform: uppercase;
	z-index: 20;
  }
  
  .load-text span {
	-webkit-animation: loading 1s infinite alternate;
	animation: loading 1s infinite alternate;
	color: var(--color-heading);
  }
  
  .load-text span:nth-child(1) {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
  }
  
  .load-text span:nth-child(2) {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
  }
  
  .load-text span:nth-child(3) {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
  }
  
  .load-text span:nth-child(4) {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
  }
  
  .load-text span:nth-child(5) {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
  }
  
  .load-text span:nth-child(6) {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
  }
  
  .load-text span:nth-child(7) {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
  }
  
  @-webkit-keyframes loading {
	0% {
	  opacity: 1;
	}
	100% {
	  opacity: 0;
	}
  }
  
  @keyframes loading {
	0% {
	  opacity: 1;
	}
	100% {
	  opacity: 0;
	}
  }