/*===============================
  Img Box
  ===============================*/
.img-box {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;

    &.v2 {
        @media (min-width: 568px) {
            height: auto;
        }

        @media (min-width: 1200px) {
            height: 400px;
        }

        @media (min-width: 1400px) {
            height: 447px;
        }
    }

    .img-primary {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: 0.8s;
        background-size: cover;
        object-fit: cover;
        transform: translateX(50%) scaleX(2);
        opacity: 0;
        filter: blur(10px);
    }

    .img-primary {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transform: translateX(50%) scaleX(2);
        opacity: 0;
        filter: blur(10px);
    }

    .img-secondary {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .5s ease;
    }

    &:hover {
        .img-secondary {
            transform: translateX(-50%) scaleX(2);
            opacity: 0;
            filter: blur(10px);
        }

        .img-primary {
            transform: translateX(0) scaleX(1);
            opacity: 1;
            filter: blur(0);
        }
    }

    .blog-tags {
        position: absolute;
        top: 10px;
        left: 10px;
        display: inline-flex;
        padding: 5px 15px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        z-index: 10;
        border-radius: 999px;
        background: var(--color-gray-2);

        li {
            display: inline-flex;
            gap: 5px;
            color: var(--color-heading);
            font-family: var(--font-secondary);
            font-size: 14px;
            font-weight: 400;
            line-height: 160%;
            margin: 0;

            i {
                font-size: 11px;
            }
        }
    }
}

.latest-portfolio-card-style-two{
    &:hover{
        .img-secondary {
            transform: translateX(-50%) scaleX(2);
            opacity: 0;
            filter: blur(10px);
        }

        .img-primary {
            transform: translateX(0) scaleX(1);
            opacity: 1;
            filter: blur(0);
        }
    }
}

.latest-portfolio-card.v5{
    &:hover {
        .img-secondary {
            transform: translateX(-50%) scaleX(2);
            opacity: 0;
            filter: blur(10px);
        }

        .img-primary {
            transform: translateX(0) scaleX(1);
            opacity: 1;
            filter: blur(0);
        }
    }
}

/*===============================
  Blog Card
  ===============================*/

.blog-card {
    border-radius: 20px;
    border: 2px solid var(--color-border);
    overflow: hidden;
    height: 100%;
    .blog-content-wrap {
        padding: 30px;

        @media #{$lg-layout} {
            padding: 20px 20px;
        }

        @media #{$md-layout} {
            padding: 20px 20px;
        }

        @media #{$sm-layout} {
            padding: 20px 20px;
        }

        .blog-title {
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 16px;

            @media #{$lg-layout} {
                margin-bottom: 12px;
            }

            @media #{$md-layout} {
                margin-bottom: 12px;
            }

            @media #{$sm-layout} {
                margin-bottom: 12px;
            }

            &.v2 {
                &:hover {
                    a {
                        color: var(--color-primary);
                    }
                }
            }
        }
    }

    .read-more-btn {
        color: var(--color-gray);
        font-family: var(--font-secondary);
        font-size: 13px;
        font-weight: 400;
        line-height: 27px;
        display: inline-block;
        align-items: center;
        transition: var(--transition);
        letter-spacing: 0.5px;

        span {
            display: inline-block;
            padding-left: 3px;
        }

        &:hover {
            color: var(--color-primary);
        }
    }
}


/*=========================
  Blog Card Style Two
  =========================*/
.blog-card-style-two {
    border-radius: 20px;
    background: var(--color-gray-2);
    overflow: hidden;
    height: 100%;
    .blog-card-img {
        position: relative;

        span {
            position: absolute;
            top: 0;
            left: 0;
            color: var(--color-white);
            font-family: var(--font-primary);
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            background: #141414cc;
            padding: 5px 20px;
            border-radius: 0px 10px 10px 0px;
            z-index: 10;
        }
    }

    .blog-content-wrap {
        padding: 20px;

        @media (min-width: 576px) {
            padding: 20px 40px 40px 40px;
        }

        @media #{$lg-layout} {
            padding: 20px;
        }

        @media #{$md-layout} {
            padding: 20px;
        }

        @media #{$sm-layout} {
            padding: 20px;
        }

        .blog-tags {
            ul {
                display: flex;
                align-items: center;
                gap: 15px;

                li {
                    margin: 0;

                    a {
                        color: var(--color-gray);
                        font-family: var(--font-secondary);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;

                        i {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .blog-title {
            margin-top: 12px;
            margin-bottom: 30px;
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
        }
    }
}


/*=========================
  Blog Inner
  =========================*/

.signle-side-bar {
    background: var(--color-gray-2);
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 40px;

    @media #{$sm-layout} {
        padding: 30px 20px;
    }

    &:last-child {
        margin-bottom: 0;
        margin-bottom: 0;
        position: sticky;
        top: 120px;
    }

    &.search-area {
        padding: 40px;
        position: relative;
        input{
            height: 60px;
            border-radius: 8px;
            padding-right: 60px;
        }
        button{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }   

    /*== Search Area ==*/
    .search-area {
        display: flex;
        align-items: center;

        input {
            &::placeholder {
                color: var(--color-heading);
                font-family: var(--font-secondary);
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
            }
        }

        button {
            border: 0;
            width: auto;
            padding: 0;
        }

        i {
            min-width: 60px;
            min-height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: var(--color-primary);
            color: var(--color-white);
        }
    }

    /*== Header ==*/
    .header {
        .title {
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            position: relative;
            display: inline-flex;
            margin-bottom: 24px;

            &::after {
                position: absolute;
                right: -20px;
                top: 13px;
                content: " ";
                width: 5px;
                height: 5px;
                background: var(--color-primary);
                border-radius: 100px;
            }
        }
    }

    /*== Recent Post Area ==*/
    &.recent-post-area {
        .body {
            .single-post {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 12px;
                border-bottom: 2px solid var(--color-border);
                margin-bottom: 12px;
                color: var(--color-gray);

                &:hover {
                    color: var(--color-primary);

                    span {
                        color: var(--color-primary);

                        .post-title {
                            color: var(--color-primary);
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                }

                .single-post-left {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .post-title {
                        color: var(--color-gray);
                        font-family: var(--font-secondary);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 26px;
                        transition: all 0.4s ease;
                    }

                    i {
                        transition: all 0.4s ease;

                        &:hover {
                            color: var(--color-primary);
                        }
                    }
                }

                .post-num {
                    color: var(--color-gray);
                    font-family: var(--font-secondary);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 160%;
                    transition: all 0.4s ease;
                }
            }

            .single-post-card {
                display: flex;
                align-items: center;
                gap: 15px;
                padding-bottom: 20px;
                border-bottom: 2px solid var(--color-border);
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                }

                .single-post-card-img {
                    min-width: 80px;
                    min-height: 80px;
                    width: 80px;

                    img {
                        border-radius: 8px;
                        min-width: 80px;
                        min-height: 80px;
                        width: 80px;
                    }
                }

                .single-post-right {
                    .single-post-top {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        margin-bottom: 8px;

                        .post-title {
                            color: var(--color-gray);
                            font-family: var(--font-secondary);
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 160%;
                            transition: var(--transition);
                        }

                        i {
                            color: var(--color-primary);
                            font-size: 12px;
                        }
                    }

                    .post-title {
                        color: var(--color-heading);
                        font-family: var(--font-primary);
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 27px;
                        margin-bottom: 0;

                        &:hover {
                            a {
                                color: var(--color-primary);
                            }
                        }
                    }
                }
            }
        }
    }

    /*== About Me Details ==*/
    .about-me-details {
        .about-me-details-head {
            display: flex;
            margin-bottom: 30px;
            gap: 20px;
            align-items: center;

            .about-me-img {
                width: 150px;
                height: auto;
            }

            .about-me-right-content {
                .title {
                    color: var(--color-heading);
                    font-family: var(--font-primary);
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 27px;
                    margin-bottom: 5px;
                }

                .para {
                    color: var(--color-gray);
                    font-family: var(--font-secondary);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 11px;
                }
            }
        }

        .about-me-para {
            color: var(--color-gray);
            font-family: var(--font-secondary);
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
        }
    }

    /*== Tags Wrapper ==*/
    .tags-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        .tag-link {
            color: var(--color-heading);
            font-family: var(--font-secondary);
            font-size: 14px;
            font-weight: 400;
            padding: 0px 21px;
            border-radius: 100px;
            background: #060606;
            display: block;
            transition: 0.3s;
            height: 40px;
            line-height: 40px;

            &:hover {
                background: var(--color-primary);
                transform: translateY(-4px);
            }
        }
    }

    /*== Service List Area ==*/
    &.service-list-area {
        .single-service {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 10px;
            border-bottom: 2px solid var(--color-border);
            margin-bottom: 10px;

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
            }

            .service-title {
                color: var(--color-gray);
                font-family: var(--font-secondary);
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                margin-bottom: 0;
                transition: all 0.4s ease;
            }

            i {
                color: var(--color-gray);
            }

            &:hover {

                .service-title,
                .service-icon,
                i {
                    color: var(--color-primary);
                }
            }
        }
    }

    /*== Project Details Area ==*/
    &.project-details-area {
        .project-details-info {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;

            span {
                color: var(--color-gray);
                font-family: var(--font-secondary);
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                margin-left: 10px;
            }
        }
    }
}

/*=========================
  Blog Classic Card
  =========================*/
.blog-classic-card {
    border-radius: 20px;
    background: var(--color-gray-2);
    overflow: hidden;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    .img-box {
        min-height: 462px;

        @media #{$md-layout} {
            min-height: auto;
        }

        @media #{$sm-layout} {
            min-height: auto;
        }

        img {
            height: auto;
        }
    }

    .blog-classic-content {
        padding: 30px 40px 40px 40px;

        @media #{$sm-layout} {
            padding: 30px 20px;
        }

        .title {
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 32px;
            font-weight: 700;
            line-height: 130%;
            text-transform: capitalize;
            margin-bottom: 20px;

            @media #{$sm-layout} {
                margin-bottom: 10px;
            }
            @media #{$large-mobile} {
                font-size: 22px;
            }

            &:hover {
                a {
                    color: var(--color-primary);
                }
            }
        }

        .para {
            color: var(--color-gray);
            font-family: var(--font-secondary);
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 40px;

            @media #{$sm-layout} {
                margin-bottom: 18px;
            }
        }
    }
}

/*=========================
  Blog Classic Tag
  =========================*/
.blog-classic-tag {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    @media (min-width: 576px) {
        gap: 30px;
    }

    .title {
        color: var(--color-gray);
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        margin-bottom: 0;
    }

    ul {
        display: flex;
        align-items: center;

        gap: 5px;
        flex-wrap: wrap;

        @media (min-width: 576px) {
            gap: 30px;

        }

        li {
            margin: 0;

            .tag-wrap {
                display: flex;
                align-items: center;
                gap: 8px;

                .tag-title {
                    color: var(--color-gray);
                    font-family: var(--font-secondary);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.about-me-social-link {
    display: flex;
    gap: 10px;
    align-items: center;

    a {
        &:hover {
            color: var(--color-primary);
        }
    }
}

/*=========================
  Tmp Pagination Button
  =========================*/
.tmp-pagination-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;



    .pagination-btn {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 500px;
        background: var(--color-gray-2);
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        transition: all 0.4s ease;
        @media #{$small-mobile} {
            width: 45px;
            height: 45px;        
        }
        &:hover {
            color: var(--color-primary);
            background: var(--color-primary);
            color: #fff;
        }

        &.active {
            color: var(--color-primary);
            background: var(--color-primary);
            color: #fff;
        }
    }
}

/*=========================
  Blog Detail left Area
  =========================*/
.blog-details-left-area {
    .thumbnail-top {
        img {
            width: 100%;
            border-radius: 10px;
        }
    }

    .blog-details-discription {
        padding: 30px 0;

        h3.title {
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 32px;
            font-weight: 700;
            line-height: 42px;
        }

        p.disc {
            margin-bottom: 20px;
            color: var(--color-gray);
            font-family: var(--font-secondary);
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
        }
    }

    .quote-area-blog-details {
        padding: 30px;
        border-radius: 20px;
        background: var(--color-gray-2);
        z-index: 1;
        position: relative;

        @media #{$sm-layout} {
            padding: 25px;
        }

        p.disc {
            color: var(--color-gray);
            font-family: var(--font-secondary);
            font-size: 20px;
            font-weight: 400;
            line-height: 35px;
            margin-bottom: 19px;
        }

        .author {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding-left: 60px;
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            margin-bottom: 0;

            &::after {
                position: absolute;
                content: '';
                left: 0;
                top: 47%;
                height: 2px;
                width: 40px;
                background: var(--color-primary);
                z-index: 10;
            }
        }

        span {
            color: var(--color-primary);
            font-size: 160px;
            opacity: 0.09;
            position: absolute;
            right: 10px;
            line-height: 0;
            display: inline-block;
            bottom: -27px;

            i {
                font-weight: 300;
            }
        }
    }
}

/*===============================
  Our Portfolio Swiper
  ===============================*/
.our-portfolio-swiper {
    position: relative;

    .our-portfoli-swiper-card {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;

        @media (min-width: 769px) {
            flex-wrap: nowrap;

        }

        .card-right-content {
            width: 100%;

            @media (min-width: 1200px) {
                min-width: 300px;

            }

            img {
                width: 100%;
                border-radius: 20px;
            }

        }
    }
}

/*===============================
  Our Portfolio Swiper Btn Wrap
  ===============================*/
.our-portfolio-swiper-btn-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .prev-btn,
    .next-btn,
    .tmp--next-prev-btn {
        display: flex;
        align-items: center;
        gap: 30px;
        .btn-content {
            .title {
                color: var(--color-heading);
                font-family: var(--font-primary);
                font-size: 18px;
                font-weight: 700;
                line-height: 27px;
                margin-bottom: 0;
                gap: 5px;
                flex-wrap: wrap;
                display: none;
                transition: var(--transition);
                &:hover {
                    color: var(--color-primary);
                }
                @media (min-width: 768px) {
                    display: flex;
                }
            }

            .para {
                color: var(--color-gray);
                font-family: var(--font-secondary);
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                display: none;

                @media (min-width: 768px) {
                    display: flex;
                }
            }
        }
    }
    .swiper-btn-prev,
    .swiper-btn-next,
    .tmp-arrow-btn {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-heading);
        background: transparent;
        border-radius: 100px;
        border: 2px solid var(--color-border);
        transition: var(--transition);
        i {
            transition: var(--transition);
        }
        &:hover {
            background: var(--color-primary);
            border-color: var(--color-primary);
            i {
                color: var(--color-white) !important;
            }
        }
        button {
            border: 0 none;
            padding: 0;
        }
    }
}

/*===============================
  Blog Details Navigation
  ===============================*/
.blog-details-navigation {
    border-radius: 20px;
    border: 2px solid var(--color-border);
    padding: 30px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;

    .navigation-tags {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;

        @media (min-width: 768px) {
            gap: 30px;
        }

        .tag-title {
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            margin-bottom: 0;
        }

        ul {
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;

            @media (min-width: 768px) {
                gap: 20px;
            }

            li {
                margin: 0;

                .tag {
                    color: var(--color-gray);
                    font-family: var(--font-secondary);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                }
            }
        }
    }

    .social-link {
        @media (min-width: 576px) {
            margin-top: 0;
        }
    }
}

/*============================
  Comment Area Main Wrapper
  ============================*/
.comment-area-main-wrapper {

    .single-comment-audience {
        display: flex;
        align-items: center;
        gap: 28px;
        margin-bottom: 40px;
        flex-wrap: wrap;
        padding-bottom: 40px;
        border-bottom: 1px solid #202020;
        &:last-child{
            border-bottom: none;
        }
        @media (min-width: 768px) {
            flex-wrap: inherit;
        }

        .author-image {
            min-width: 95px;
            min-height: 95px;
            max-width: 150px;
            padding: 15px;
            background: var(--color-gray-2);
            border-radius: 50%;
            &.tmponhover::after {
                border-radius: 50%;
            }
        }
    }

    .right-area-commnet {
        .top-area-comment {
            display: flex;
            gap: 15px;
            margin-bottom: 5px;
            flex-wrap: wrap;

            @media (min-width: 768px) {
                gap: 30px;
            }

            .left {
                display: flex;
                gap: 15px;
                flex-wrap: wrap;
                align-items: center;

                .title {
                    color: var(--color-heading);
                    font-family: var(--font-primary);
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 27px;
                    margin-bottom: 0;
                }

                span {
                    color: var(--color-gray);
                    font-family: var(--font-secondary);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                }
            }
        }

        .disc {
            color: var(--color-gray);
            font-family: var(--font-secondary);
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
        }

        .reply-btn {
            color: var(--color-gray);
            font-family: var(--font-primary);
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 1.28px;
            text-transform: uppercase;

            &:hover {
                color: var(--color-primary);
            }
        }
    }
}

/*============================
  Blog Details Form Wrapper
  ============================*/
.blog-details-form-wrapper {
    padding: 30px;
    border-radius: 20px;
    background: var(--color-gray-2);
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);

    @media (min-width: 768px) {
        padding: 60px;
    }

    .title {
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-size: 32px;
        font-weight: 700;
        line-height: 130%;
        text-transform: capitalize;
        margin-bottom: 5px;
    }

    .subtitle {
        color: var(--color-gray);
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 40px;
        display: inline-block;
    }

    .blog-details-form {
        label {
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            margin-bottom: 8px;
        }

        .single-input {
            margin-bottom: 18px;

            input {
                border-radius: 20px;
                border: 2px solid var(--color-border);
                padding: 17px 20px;

                &:focus {
                    border: 2px solid var(--color-primary) !important;
                }

                &::placeholder {
                    color: var(--color-heading);
                    font-family: var(--font-secondary);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                }
            }
        }

        textarea {
            border-radius: 20px;
            border: 2px solid var(--color-border);
            padding: 17px 20px;
            min-height: 130px;
            resize: inherit;
            transition: var(--transition);
            &::placeholder {
                color: var(--color-heading);
                font-family: var(--font-secondary);
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
            }

            &:focus {
                border-color: var(--color-primary);
            }
        }

        .comments-btn-wrap {
            margin-top: 30px;

            .comment-btn {
                padding: 9px 30px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                width: 100%;
                border-radius: 100px;
                color: var(--color-gray);
                font-family: var(--font-primary);
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 1.28px;
                text-transform: uppercase;
                transition: all 0.4s ease;
                background: var(--color-primary);
                border: 2px solid var(--color-secondary);
                transition: var(--transition);

                &:hover {
                    border-color: var(--color-primary);
                    background: transparent;
                }
            }
        }

    }
}

.card-1-body{
    .blog-card-style-two .blog-content-wrap {
        @media #{$laptop-device} {
            padding: 10px;
        }
    }
    .custom-title {
        @media #{$laptop-device} {
            font-size: 18px;
        }
    }
    .tab-content-overlay-to-top {
        @media screen and (max-width: 1400px) {
            margin-top: 0;
        }
    }
    .tmp-contact-about-inner {
        @media #{$sm-layout} {
            flex-direction: column;
            gap: 35px;
        }
    }
}