// hover effect
.tmponhover {
    position: relative;
    overflow: hidden;
    &::before{
        content: '';
        position: absolute;
        top: var(--y);
        left: var(--x);
        transform: translate(-50%, -50%);
        background: radial-gradient(var(--color-primary), transparent, 
        transparent);
        width: 200%;
        height: 200%;
        opacity: 0;
        transition:  0.5s,top 0s,left 0s;
        border-radius: 20px;
    }
    &:hover{
        &::before{
            opacity: 1;
        }
    }
    &::after{
        content: '';
        position: absolute;
        inset: 2px;
        background: var(--color-gray-2);
        border-radius: 20px;
    }
    & > *{
        z-index: 2;
        position: relative;
    }
}
