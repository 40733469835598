/*===============================
  Price Plan Card
  ===============================*/
.price-plan-card {
    border-radius: 20px;
    background: var(--color-gray-2);
    padding: 25px;
    position: relative;
    overflow: hidden;

    @media (min-width: 576px) {
        padding: 40px;
    }

    // &.blur-style-one,
    // &.blur-style-two,
    // &.blur-style-three {
    //     &::before {
    //         transition: all 0.4s ease;
    //         content: " ";
    //         position: absolute;
    //         bottom: -313px;
    //         right: -407px;
    //         width: 474px;
    //         height: 474px;
    //         border-radius: 100%;
    //         filter: blur(227px);
    //         opacity: 0;
    //     }
    // }

    // &:hover {
    //     &.blur-style-one {
    //         &::before {
    //             background: var(--color-primary);
    //             opacity: 1;
    //         }
    //     }
     
    // }
    &.active{
        &.blur-style-one {
            &::before {
                background: var(--color-primary);
                opacity: 1;
            }
        }
    }

    &.v2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .price-plan-card-icon {
            margin-bottom: 33px;
        }
        .check-box {
            border-top: none;
            padding-bottom: 40px;
            border-bottom: 2px solid var(--color-border);
            margin-bottom: 40px;
            padding-top: 28px;
            @media #{$sm-layout} {
                margin-bottom: 20px;
                padding-top: 16px;
            }
        }
    }

    .popular-tag {
        position: absolute;
        top: 0;
        right: 0;
    }

    .price-sub-title {
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
    }

    .main-price {
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-size: 48px;
        font-weight: 700;
        line-height: 60px;
        text-transform: capitalize;
        margin-bottom: 5px;
    }

    .per-month {
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        margin-bottom: 28px;
        @media #{$sm-layout} {
            margin-bottom: 14px;
        }
    }
}

/*===============================
  Check Box
  ===============================*/
.check-box {
    border-top: 2px solid #9d9dff0d;
    padding-top: 43px;
    padding-bottom: 30px;
    @media (min-width: 992px) {
        padding-bottom: 40px;
    }
    ul {
        li {
            margin: 0 0 15px 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .check-box-item {
        display: flex;
        align-items: center;
        gap: 10px;
        .box-icon {
            color: var(--color-primary);
        }
        .box-para {
            color: var(--color-gray);
            font-family: var(--font-secondary);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
}

.pricing-table-col-2{
    border-radius: 6px;
    padding: 37px 40px 40px 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;
    @media #{$large-mobile} {
        padding: 20px;
    }
    .pricing-table-header{
        border-bottom: 1px solid #9d9dff0d;
        padding-bottom: 20px;
        margin-bottom: 34px;
        h4{
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 300;
            color: #fff;
            margin: 0;
        }
        .top p{
            font-size: 13px;
            line-height: 20px;
            color: #e2e6ffa6;
            margin: 0;
        }
        h3{
            font-size: 48px;
            color: var(--color-primary);
            font-weight: 600;
            margin: 0;
            span{
                font-size: 24px;
                color: #e2e6ffa6;
            }
        }
    }
    .feature-lists{
        margin-bottom: 80px;
        padding: 0;
        list-style: none;
        li{
            font-size: 14px;
            color: #fff;
            line-height: 24px;
            font-weight: 300;
            margin-bottom: 10px;
            font-weight: 500;
        }
    }

}
.pricing-table-items .info {
    font-size: 14px;
    color: #fff;
    line-height: 24px;
    margin: 60px 0 0 0;
    a{
        color: var(--color-primary);
        text-decoration: underline;
        line-height: 0;
        padding: 0;
        margin: 0;
        height: 0;
        margin-top: 33px;
    }
}