/*===============================
  Social Link
  ===============================*/
.social-link {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    a {
        width: 40px;
        height: 40px;
        color: var(--color-heading);
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease;
        z-index: 1;
        background: #ffffff10;
        border-radius: 100%;
        font-size: 14px;
        &::after {
            content: " ";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 100px;
            background: var(--color-primary);
            opacity: 0.06;
            transform: scale(0);
            transition: all 0.4s ease;
            z-index: -1;
        }
        &:hover {
            color: #ffffff;
            &::after {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    &.footer {
        a {
            min-width: 40px;
            min-height: 40px;
        }
    }
    &.banner {
        gap: 10px;
        a {
            border-radius: 500px;
            background: var(--color-gray-2);
            min-width: 40px;
            min-height: 40px;
            color: var(--color-heading);
            transition: var(--transition);
            &::after {
                content: " ";
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 100px;
                background: var(--color-primary);
                opacity: 0.06;
                transform: scale(0);
                transition: all 0.4s ease;
                z-index: -1;
            }
            &:hover {
                border-color: transparent;
                color: #ffffff;
                &::after {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }
}
.social-link-inner {
    display: flex;
    align-items: center;
    gap: 16px;
    a {
        transition: var(--transition);
        i {
            color: var(--color-gray);
            transition: var(--transition);
        }
        &:hover {
            color: var(--color-primary);
            i {
                color: var(--color-primary);
            }
        }
    }
}
