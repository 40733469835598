.year-of-expariance-wrapper {
    position: relative;
    overflow: hidden;
    background: var(--color-gray-2);

    &.bg-blur-style-one,
    &.bg-blur-style-two,
    &.bg-blur-style-three {
        &::after {
            content: " ";
            position: absolute;
            right: -250px;
            bottom: -367px;
            min-width: 474px;
            min-height: 474px;
            border-radius: 100px;
            filter: blur(227px);
            z-index: -1;
        }
    }

    &.bg-blur-style-one {
        &::after {
            background: var(--color-primary);
        }
    }

    border-radius: 20px;
    padding: 36px 52px 51px 52px;

    @media (min-width: 1400px) {
        padding: 36px 52px 51px 52px;
    }

    @media #{$sm-layout} {
        padding: 30px 20px;
    }

    .year-expariance-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        @media #{$lg-layout} {
            gap: 20px;
        }
        @media #{$large-mobile} {
            display: block;
        }
        @media (min-width: 568px) {
            gap: 44px;
        }
        .year-number {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 167px;
            line-height: 115%;
            font-weight: 700;
            margin-bottom: 0;
            flex-basis: 40%;
            @media #{$lg-layout} {
                font-size: 100px;
            }
            @media #{$md-layout} {
                font-size: 100px;
            }
            @media #{$sm-layout} {
                font-size: 100px;
                line-height: 1;
            }
        }
        .year-title {
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 48.14px;
            font-weight: 700;
            line-height: 130%;
            text-transform: capitalize;
            margin-bottom: 0;
            @media #{$lg-layout} {
                font-size: 37px;
            }
            @media #{$md-layout} {
                font-size: 37px;
            }
            @media #{$sm-layout} {
                font-size: 32px;
                br{
                    display: none;
                }
            }
            @media #{$large-mobile} {
                font-size: 26px;
                br{
                    display: none;
                }
            }
        }
    }
    .year-para {
        color: var(--color-heading);
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        margin-top: 12px;
    }
}


.counter-card {
    padding: 34px 7px;
    border-radius: 20px;
    background: var(--color-gray-2);
    height: 100%;
    .counter-title {
        color: var(--color-heading);
        text-align: center;
        font-family: var(--font-primary);
        font-size: 48px;
        font-weight: 700;
        line-height: 60px;
        text-transform: capitalize;
        margin-bottom: 0;
        @media #{$laptop-device} {
            font-size: 40px;
        }
    }
    .counter-para {
        color: var(--color-gray);
        text-align: center;
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        margin-top: 4px;
    }
}