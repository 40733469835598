/*===============================
  About Me Section Card
  ===============================*/
.testimonial-card {
    position: relative;
    z-index: 2;
    display: flex;
    .card-content-wrap {
        z-index: 1;
        position: relative;
        .text-doc {
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 32px;
            font-weight: 700;
            line-height: 42px;
            text-transform: capitalize;
            max-width: 376px;
            margin-bottom: 30px;
            @media #{$lg-layout} {
                font-size: 20px;
                font-weight: 700;
                line-height: 36px;
            }
            @media #{$md-layout} {
                font-size: 20px;
                font-weight: 700;
                line-height: 36px;
                max-width: 393px;
                margin-bottom: 19px;
            }
            @media #{$sm-layout} {
                font-size: 20px;
                font-weight: 700;
                line-height: 34px;
            }
            @media #{$large-mobile} {
                font-size: 21px;
                font-weight: 600;
                line-height: 28px;
            }
        }
        .card-title {
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 8px;
            @media #{$sm-layout} {
                font-size: 17px;
                font-weight: 700;
                line-height: 22px;
                margin-bottom: 2px;
            }
        }
        .card-para {
            color: var(--color-gray);
            font-family: var(--font-secondary);
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 0;
        }
        .testimonital-icon {
            width: 110px;
            height: 93px;
            margin-top: 29px;
            @media #{$sm-layout} {
                width: 79px;
                height: 71px;
                margin-top: 10px;
            }
            img {
                width: 100%;
            }
        }
    }

    .testimonial-card-img {
        display: flex;
        justify-content: end;
        position: absolute;
        right: 0;
        bottom: 15%;
        width: 60%;
        @media #{$lg-layout} {
            bottom: 7%;
            width: 64%;
        }
        @media #{$md-layout} {
            bottom: 7%;
            width: 39%;
        }
        @media #{$sm-layout} {
            bottom: 7%;
            width: 50%;
        }
        img {
            object-fit: cover;
        }
    }
    &.style-2 {
        background: var(--color-gray-2);
        border-radius: 20px;
        padding: 40px;
        @media #{$sm-layout} {
            padding: 30px 20px;
        }
        .content {
            .text-doc {
                line-height: 1.7;
                font-size: 18px;
                font-family: var(--font-secondary);
                color: var(--color-gray);
                font-weight: 400;
                margin-top: 43px;
                margin-bottom: 38px;

                @media #{$sm-layout} {
                    font-size: 16px;
                    margin-bottom: 18px;
                }
            }

            .card-title {
                font-family: var(--font-primary);
                font-size: 18px;
                font-weight: 700;
                color: var(--color-heading);
                margin-bottom: 12px;
            }

            .card-para {
                font-family: var(--font-secondary);
                color: var(--color-gray);
                font-size: 14px;
            }
        }
    }


}

.testimonial-wrapper {
    position: relative;
    z-index: 1;

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        border: 1px solid var(--color-border);
        transition: all 0.4s ease;

        i {
            color: var(--color-heading);
            font-size: 24px;
            font-weight: 300;
            transition: all 0.4s ease;
        }

        &:hover {
            background: var(--color-primary);

            i {
                color: #ffffff;
                transform: rotate(0deg);
            }
        }

        &::after {
            display: none;
        }
    }

    .swiper-button-prev {
        display: none;

        @media (min-width: 1700px) {
            left: 150px;
            display: inline-flex;

        }

        i {
            transform: rotate(45deg);
        }
    }

    .swiper-button-next {
        display: none;

        @media (min-width: 1700px) {
            right: 150px;
            display: inline-flex;

        }

        i {
            transform: rotate(-45deg);
        }
    }
}