/*-------------------------
    Offcanvas Menu Style  
---------------------------*/
.tmp-sidebar-area {
    width: 400px;
    position: fixed;
    right: 0;
    top: 0;
    background: var(--color-gray-2);
    z-index: 99999;
    height: 100%;
    transform: translate3d(100%, 0, 0);
    overflow: hidden;
    overscroll-behavior: contain;
    transition: transform 0.85s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s step-end;
    .inner {
        position: relative;
        overflow: hidden;
        height: 100%;
        overflow-y: auto;
        
        .top-area {
            padding: 16px 20px;
            background: #151515;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
        }

        .image-area-feature {
            img {
                border-radius: 0;
            }
        }

        .content-wrapper {
            padding: 30px;
            .title {
                color: var(--color-heading);
                line-height: 1.4;
            }
            .disc {
                font-family: var(--font-secondary);
                color: var(--color-gray);
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

    &.tmp_side_bar_open {
        transform: translate3d(0, 0, 0);
        transition: transform 0.85s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s step-start;
    }

    .short-contact-area {
        .single-contact {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            gap: 20px;
            i {
                height: 45px;
                width: 45px;
                background: transparent;
                display: flex;
                align-items: center;
                color: var(--color-heading);
                justify-content: center;
                font-size: 18px;
                border-radius: 5px;
                border: 1px solid var(--color-border);
            }
            .information {
                span {
                    display: block;
                    color: var(--color-primary);
                    font-family: var(--font-primary) !important;
                    text-transform: uppercase;
                    font-size: 15px;
                    margin-bottom: 0;
                    font-weight: 600;
                }
    
                .number {
                    color: var(--color-heading);
                    font-family: var(--font-secondary);
                }
            }
        }
    }
}

// Fimal Close Menu 
.overlay_close_side_menu {
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1091;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    cursor: url(../images/icons/cursor-x.webp), pointer;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(15px);
}
.sidemenu-active {
    .overlay_close_side_menu {
        opacity: 1;
        visibility: visible;
    }
}


