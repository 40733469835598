/*===============================
  Get In Touch Wrapper
  ===============================*/
.get-in-touch-wrapper {
    border-radius: 20px;
    background: var(--color-gray-2);
    padding: 20px 30px;
    @media (min-width: 576px) {
        padding: 80px 60px;
    }
    @media #{$md-layout} {
        padding: 40px 40px;
    }
    @media #{$sm-layout} {
        padding: 30px 20px;
    }
}

/*===============================
  Counter Inner
  ===============================*/
.contact-inner {
    .title-left {
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;

        @media (min-width: 576px) {
            font-size: 68px;
            line-height: 78px;
        }
        @media #{$lg-layout} {
            font-size: 50px;
            line-height: 1.23;
        }
        @media #{$md-layout} {
            font-size: 50px;
            line-height: 1.23;
        }
        @media #{$sm-layout} {
            font-size: 32px;
            line-height: 1.2;
        }

    }

}

/*===============================
  Contact Form
  ===============================*/
.contact-form {
    .contact-form-wrapper {
        .form-group {
            margin-bottom: 0;
            border: none !important;
            input {
                background: transparent;
                height: inherit;
                padding: 15px 20px;
                font-size: 16px;
                color: var(--color-heading);
                font-weight: 600;
                border-radius: 10px;
                border: 2px solid var(--color-border);
                &:focus {
                    border-color: var(--color-primary);
                }

                &::placeholder {
                    color: var(--color-gray);
                    font-family: var(--font-secondary);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                }
            }

            textarea {
                background: transparent;
                font-size: 16px;
                color: var(--color-heading);
                font-weight: 600;
                border-radius: 10px;
                border: 2px solid var(--color-border);

                &::placeholder {
                    color: var(--color-gray);
                    font-family: var(--font-secondary);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                }
                
                &:focus {
                    border-color: var(--color-primary);
                }
            }

           
        }
    }

    .tpm-btn-primary {
        width: 100%;

        button {
            justify-content: center;
        }

        span {
            justify-content: center;
        }
    }
}

/*===============================
  Contact Info
  ===============================*/
.contact-info {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 50px 0;
    width: 100%;
    background: #0F0F0F;
    .contact-icon {
        width: 70px;
        height: 70px;
        background: rgba(255, 73, 74, 0.2);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-primary);
        margin-bottom: 25px;
        font-size: 30px;
    }
    .title {
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-size: 32px;
        font-weight: 700;
        line-height: 130%;
        text-transform: capitalize;
        margin-bottom: 15px;
    }
    .para {
        color: var(--color-gray);
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

/*===============================
  Contact Get In Touch Wrap
  ===============================*/
.contact-get-in-touch-wrap {
    .get-in-touch-wrapper {
        .section-head {
            padding-right: 80px;
            position: sticky;
            top: 120px;
            @media #{$md-layout} {
                padding-right: 0;
            }
            @media #{$sm-layout} {
                padding-right: 0;
            }
        }
    }
}


#form-messages{
    &.error{
        color: var(--color-danger);
    }
    &.success{
        color: var(--color-success);
    }
}


.contact-area-left-portfolio-fixed{
    .form-group{
        margin-bottom: 20px;
    }
    input,
    textarea{
        background: var(--color-gray-2);
        border: 1px solid #0F0F0F;
        &:focus{
            border: 1px solid var(--color-primary) !important;
        }
    }
    input{
        height: 70px;
        padding: 0 20px;
    }
    textarea{
        padding-top: 20px;
        padding-left: 20px;
        min-height: 193px;
        border-radius: var(--radius);
        resize: none;
        padding: 15px;
        font-size: 16px;
    }
}