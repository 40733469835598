/*--------------------
    Main Menu Style  
----------------------*/
.tmp-mainmenu-nav {
    .tmp-mainmenu {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;

        &>li {
            &>a {
                color: var(--color-heading);
                font-weight: 700;

                &:hover {
                    color: var(--color-primary) !important;
                }
            }

            a {
                &:hover {
                    color: var(--color-primary);
                }
            }
        }

        & > li {
            position: relative;
            margin: 10px 4px;
            & > a {
                padding: 4px 12px !important;
                color: var(--color-heading) !important;
                position: relative;
                display: inline-block;
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) scale(0.7);
                    left: 0;
                    background: var(--color-primary);
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    opacity: 0;
                    visibility: hidden;
                    transition: var(--transition);
                    z-index: -1;
                }
                i {
                    transition: var(--transition);
                    font-size: 10px;
                    margin-left: 3px;
                }
                &.active {
                    color: var(--color-primary) !important;
                    &::after {
                        opacity: 0.1;
                        visibility: visible;
                        transform: translateY(-50%) scale(1);
                    }
                }
            }
            &:hover,
            &.current {
                & > a {
                    color: var(--color-primary) !important;
                    &::after {
                        opacity: 0.1;
                        visibility: visible;
                        transform: translateY(-50%) scale(1);
                    }
                    i {
                        transform: rotate(180deg);
                    }
                }
            }

            &.has-dropdown {
                margin-right: 5px;
                margin-top: 0;
                margin-bottom: 0;
                padding: 11px 0;
                .submenu {
                    padding: 10px;
                    min-width: 230px;
                    height: auto;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 90;
                    opacity: 0;
                    visibility: hidden;
                    text-align: left;
                    transition: 0.5s;
                    transform: scaleY(0);
                    border-radius: 10px;
                    background: var(--color-gray-2);
                    display: inline-block;
                    box-shadow: -3px 0px 20px 4px rgb(100 95 95 / 8%);
                    transform-origin: 0 0;
                    margin-top: 0;

                    li {
                        a {
                            color: var(--color-heading);
                            font-weight: 600;

                        }
                    }
                }

                &:hover {
                    .submenu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                        transform: scaleY(1);
                        overflow: inherit;
                    }
                }
            }

            &.menu-item-open {
                & > a {
                    color: var(--color-primary) !important;
                    &::after{
                        opacity: 0.1;
                        visibility: visible;
                        transform: translateY(-50%) scale(1);
                    }
                }
            }
        }

        // Dropdown Menu 
        .has-dropdown {
            .submenu {
                padding: 0;
                border: none !important;
                overflow: hidden;

                li {
                    position: relative;
                    display: block;
                    cursor: pointer;
                    margin-bottom: 0;
                    margin-top: 0;
                    &::after {
                        position: absolute;
                        content: "\f105";
                        font-family: "fontawesome";
                        font-size: 14px;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 20px;
                        opacity: 0;
                        transition: .2s;
                        color: var(--color-primary);
                    }
                    a {
                        display: block;
                        position: relative;
                        transition: var(--transition);
                        padding: 3px;
                        &::before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                            background: var(--color-primary);
                            width: 100%;
                            height: 28px;
                            border-radius: 4px;
                            opacity: 0;
                            visibility: hidden;
                            transition: var(--transition);
                            z-index: -1;
                        }

                        &:hover,
                        &.active {
                            &::before {
                                opacity: 0.1;
                                visibility: visible;
                                transform: translateY(-50%);
                            }
                        }
                    }

                    &>a.active {
                        padding-left: 15px;
                    }

                    &:hover {
                        &>a {
                            color: var(--color-primary);
                            padding-left: 15px;
                        }

                        &::after {
                            right: 15px;
                            opacity: 1;
                        }
                    }

                    &.menu-item {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}