/*===============================
  Latest Portfolio Area
  ===============================*/
.custom-column-grid {
    .row {
        --bs-gutter-x: 5rem;
        --bs-gutter-y: 4rem;
        @media #{$lg-layout} {
            --bs-gutter-x: 2rem;
            --bs-gutter-y: 2rem;
        }
        @media #{$md-layout} {
            --bs-gutter-x: 2rem;
            --bs-gutter-y: 2rem;
        }
        @media #{$sm-layout} {
            --bs-gutter-x: 15px;
            --bs-gutter-y: 30px;
        }
    }
}

/*===============================
  Latest Portfolio Custom Title
  ===============================*/
.latest-portfolio-custom-title {
    text-align: center;
    -webkit-text-stroke: 1px #242424;
    color: transparent;
    font-family: var(--font-primary);
    text-transform: uppercase;
    margin-bottom: 34px;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    @media (min-width: 576px) {
        font-size: 100px;
        line-height: 110px;
    }
    @media (min-width: 1640px) {
        font-size: 165px;
        line-height: 115%;
    }
    @media #{$md-layout} {
        font-size: 65px;
    }
    @media #{$sm-layout} {
        font-size: 65px;
    }
}

/*===============================
  Latest Portfolio Card
  ===============================*/
.latest-portfolio-card {
    .portfoli-card-img {
        border-radius: 20px;
        background: var(--color-gray-2);
        padding: 20px;
        border: 2px solid var(--color-border);
        transition: var(--transition);

        @media #{$md-layout} {
            padding: 10px;
        }
        @media #{$sm-layout} {
            padding: 10px;
        }
        @media #{$large-mobile} {
            padding: 5px;
        }
        a {
            display: block;
            transition: var(--transition);
        }
        img {
            border-radius: 20px;
            width: 100%;
            object-fit: cover;
            transition: var(--transition);
        }
    }

    &:hover {
        .portfoli-card-img {
            img {
                transform: scale(1.03);
            }
        }
        
    }

    &.v5 {
        .portfoli-card-img {
            padding: 0;
            overflow: hidden;
            position: relative;

            .img-link-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 80px;
                height: 80px;
                border-radius: 100px;
                border: 2px solid var(--color-primary);
                font-size: 24px;
                font-weight: 900;
                opacity: 0;
                transition: all 0.4s ease;
                z-index: 10;
                background: var(--color-primary);
                @media (min-width: 992px) {
                    width: 112px;
                    height: 112px;
                }
                i {
                    transform: rotate(45deg);
                    transition: all 0.4s ease;
                    color: var(--color-white);
                }

                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    color: var(--color-heading);
                    i {
                        transform: rotate(90deg);
                    }
                }
            }

            &:hover {
                .img-link-icon {
                    opacity: 1;
                }

            }
        }

        .portfolio-card-title {
            &:hover {
                a {
                    color: var(--color-primary);
                }
            }
        }
    }

    .portfolio-card-content-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 33px;
        gap: 20px;
        
        @media #{$sm-layout} {
            margin-top: 15px;
        }
      

        .portfolio-card-title {
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 0;
            transition: var(--transition);
            @media #{$small-mobile} {
                font-size: 20px;
            }
            &:hover {
                color: var(--color-primary);
            }
        }

        .portfoli-card-para {
            color: var(--color-gray);
            font-family: var(--font-secondary);
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            margin-top: 10px;
        }
    }
}



/*===============================
  Latest Portfolio Area
  ===============================*/
.latest-portfolio-area {
    .see-all-btn-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
    }
}



/*===================================
  Latest Portfolio Card Style Two
  ===================================*/
.latest-portfolio-card-style-two {
    border-radius: 20px;
    background: var(--color-gray-2);
    padding: 20px;
    height: 100%;
    @media (min-width: 576px) {
        padding: 20px;
    }
    @media #{$large-mobile} {
        padding: 10px;
    }
    .portfoli-card-img {
        img {
            border-radius: 10px
        }
    }

    .portfolio-card-title {
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        text-transform: capitalize;
        margin-top: 20px;
        margin-bottom: 13px;
        @media #{$sm-layout} {
            font-size: 26px;
            line-height: 34px;
        }
    }

    .portfolio-card-content-wrap {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px 0;
    }
}

/*===============================
  Tags Items
  ===============================*/
.tag-items {
    ul {
        display: flex;
        align-items: center;
        gap: 10px;
        @media #{$sm-layout} {
            gap: 6px;
            flex-wrap: wrap;
        }
        li {
            margin: 0;
            margin-bottom: 6px;
            a {
                border-radius: 500px;
                border: 2px solid var(--color-border);
                color: var(--color-gray);
                font-family: var(--font-secondary);
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                padding: 5px 15px;
                transition: var(--transition);
                &:hover {
                    background: var(--color-primary);
                    border-color: var(--color-primary);
                    color: #ffffff;
                }
            }
        }
    }
}

/*===================================
  Latest Portfolio Tabs Area
  ===================================*/
.latest-portfolio-tabs-area {
    .nav-tabs {
        border-bottom: 1px solid none;
        border: none;
    }
    .nav {
        display: flex;
        justify-content: center;
        gap: 10px;
        @media (min-width: 776px) {
            gap: 15px;
        }
        
        li {
            margin: 0;
        }
        .nav-link {
            padding: 6px 25px;
            border-radius: 999px;
            color: var(--color-heading);
            font-family: var(--font-primary);
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            background: var(--color-gray-2);
            border: none;
            &.active {
                background: var(--color-primary) !important;
                color: #ffffff !important;
                border: none;
            }

        }


    }

    .tab-content {
        margin-top: 60px;
        position: relative;
        z-index: 1;
        &.bg-blur-style-one,
        &.bg-blur-style-two,
        &.bg-blur-style-three {

            &::after,
            &::before {
                content: " ";
                position: absolute;
                width: 304px;
                height: 304px;
                opacity: 0.6;
                filter: blur(280px);
                z-index: -1;
            }

            &::after {
                left: 0;
                bottom: 172px;
            }
            &::before {
                right: 0;
                bottom: 65%;
            }
        }
        &.bg-blur-style-one {
            &::after,
            &::before {
                background: var(--color-primary);
            }
        }
        img {
            border-radius: 20px;
            width: 100%;
            object-fit: cover;
        }
        .tab-right-img-wrap {
            .small-img {
                &:last-child {
                    margin-top: 30px;
                }
            }
        }
    }
}


/*----------------------
    Tmp Portfolio  
-----------------------*/
.tmp-portfolio {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    z-index: 1;
    img {
        width: 100%;
    }
    &::after { 
        content: " ";
        position: absolute; 
        right: 0;
        top: 0;
        background: linear-gradient(var(--color-primary) 10%,#000);
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.4s ease;
        z-index: 1;
    }
    &:hover {
        &::after {
            opacity: 0.85;
        }
        .portfolio-card-content-wrap {
            bottom: 30px;
            opacity: 1;
            pointer-events: inherit;
            @media #{$md-layout} {
                bottom: 15px;
            }
            @media #{$sm-layout} {
                bottom: 15px;
            }
        }
    }
    .portfolio-card-content-wrap {
        position: absolute;
        bottom: 0;
        left: 30px;
        right: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        transition: all 0.4s ease;
        z-index: 999;
        @media #{$md-layout} {
            left: 15px;
            right: 15px;
        }
        @media #{$sm-layout} {
            left: 15px;
            right: 15px;
        }
        .portfoli-card-para {
            margin-bottom: 10px;
            font-size: 16px;
            font-family: var(--font-secondary);
            color: var(--color-white);
        }
        .portfolio-card-title {
            font-size: 24px;
            margin-bottom: 0;
            color: var(--color-white);
            line-height: 1.3;
            @media #{$md-layout} {
                font-size: 18px;
            }
            @media #{$sm-layout} {
                font-size: 18px;
            }
            &:hover{
                color: #fff !important;
            }
        }
    }

    .tmp-arrow-icon-btn i {
        color: var(--color-white);
    }
}

.over_link{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}