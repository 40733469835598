/* Service Card v1*/
.service-card-v1 {
    border-radius: 20px;
    border: 1px solid rgba(255, 73, 74, 0.16);
    padding: 40px 0 40px 0;
    transition: 0.4s;
    @media #{$md-layout} {
        padding: 30px 0 30px 0;
    }
    @media #{$sm-layout} {
        padding: 30px 0 30px 0;
    }
    &.vc-primary-2nd {
        border: 1px solid rgba(255, 73, 74, 0.16);
        .service-card-icon {
            color: var(--color-primary);
        }
    }
    .service-card-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 55px;
        min-height: 55px;
        color: var(--color-primary);
        font-size: 50px;
        margin-bottom: 30px;
        @media #{$md-layout} {
            margin-bottom: 16px;
        }
        @media #{$sm-layout} {
            margin-bottom: 16px;
        }
    }

    .service-title {
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        text-align: center;
        margin-bottom: 9px;
    }

    .service-para {
        color: var(--color-gray);
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
    }
}

/* Service Card v2*/
.service-card-v2 {
    border-radius: 20px;
    background: var(--color-gray-2);
    padding: 36px 40px 36px 40px;
    margin-bottom: 20px;
    display: block;
    position: relative;
    overflow: hidden;

    @media #{$sm-layout} {
        padding: 20px;
    }

    @media #{$large-mobile} {
        padding: 30px 15px;
    }

    // &::after {
    //     transition: all 0.8s ease;
    //     content: " ";
    //     position: absolute;
    //     bottom: -313px;
    //     right: -407px;
    //     width: 474px;
    //     height: 474px;
    //     border-radius: 100%;
    //     filter: blur(227px);
    //     opacity: 0;
    //     pointer-events: none;
    // }

    // &:hover {
    //     &::after {
    //         background: var(--color-primary);
    //         opacity: 1;
    //     }
    // }

    &:last-child {
        margin-bottom: 0;
    }

    .service-card-num {
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 0;

        span {
            margin-right: 15px;
        }
    }

    .service-para {
        color: var(--color-gray);
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        margin-top: 22px;

        @media #{$sm-layout} {
            margin-top: 7px;
        }
    }
}




.service-card-user-image {
    display: flex;
    justify-content: center;

    @media (min-width: 1200px) {
        margin-left: 70px;
    }
}

.inner {
    .ft-link li span {
        background: var(--color-primary) !important;

        i {
            color: #fff;
        }
    }
}



.service-details-area-wrapper {
    .service-thumnail-wrap {
        margin-bottom: 20px;

        img {
            border-radius: 20px;
        }
    }

    .title {
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-weight: 700;
        font-size: 42px;
        line-height: 52px;
        margin-bottom: 20px;

        @media (min-width: 576px) {
            font-size: 48px;
            line-height: 60px;
        }

        @media #{$sm-layout} {
            font-size: 40px;
            line-height: 52px;
        }

    }

    .doc-para {
        color: var(--color-gray);
        font-family: var(--font-secondary);
        font-weight: 400;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .title-mini {
        color: var(--color-heading);
        font-family: var(--font-primary);
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 20px;
    }
}





/*=========================
  Services Widget
  =========================*/

.services-widget {
    position: relative;

    &.v1 {
        .service-item {
            .my-skill-card {
                .card-para {
                    color: white;
                }
            }

            &.mleave {
                .my-skill-card {
                    .card-para {
                        color: var(--color-gray);
                    }

                    .card-icon {
                        color: var(--color-primary);
                    }
                }
            }
        }

        .active-bg {
            background: var(--color-primary);
        }


        .mleave {
            .my-skill-card .card-icon {
                border: 2px solid var(--color-border);
            }
            .my-skill-card .card-icon i {
                color: var(--color-body);
            }
        }

        .my-skill-card .card-icon {
            border: 2px solid var(--color-border);
        }
        .my-skill-card .card-icon i {
            color: var(--color-heading);
        }


    }

    &.v2 {
        .service-item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .expertise-card-logo {
                background: transparent !important;
            }

            .para {
                color: var(--color-heading);

            }

            &.mleave {
                .expertise-card-logo {
                    background: var(--color-primary) !important;
                }

                .para {
                    color: var(--color-gray);
                }
            }
        }

        .active-bg {
            border-radius: 20px;
            background: #ff014f1a;
            backdrop-filter: blur(35px);
        }
    }

    &.v3 {
        .service-item {
            .my-skill-card {
                .card-para {
                    color: white;
                }
            }

            &.mleave {
                .my-skill-card {
                    .card-para {
                        color: var(--color-gray);
                    }

                    .card-icon {
                        color: var(--color-primary);
                    }
                }
            }
        }

        .active-bg {
            background: var(--color-primary);
        }
    }

    &.v4 {
        .service-item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .expertise-card-logo {
                background: var(--color-secondary) !important;
            }

            .para {
                color: var(--color-heading);

            }

            &.mleave {
                .expertise-card-logo {
                    background: var(--color-primary) !important;
                }

                .para {
                    color: var(--color-gray);
                }
            }
        }

        .active-bg {
            border-radius: 20px;
            background: #fdc44817;
            backdrop-filter: blur(10px);
        }
    }

    &.v5 {
        .service-item {
            .my-skill-card {
                .card-para {
                    color: white;
                }
            }

            &.mleave {
                .my-skill-card {
                    .card-para {
                        color: var(--color-gray);
                    }

                    .card-icon {
                        color: var(--color-primary);
                    }
                }
            }
        }

        .active-bg {

            background: var(--color-primary);


        }
    }

    &.v6 {
        .service-item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .expertise-card-logo {
                background: var(--color-secondary) !important;
            }

            .para {
                color: var(--color-heading);

            }

            &.mleave {
                .expertise-card-logo {
                    background: var(--color-primary) !important;
                }

                .para {
                    color: var(--color-gray);
                }
            }
        }

        .active-bg {
            border-radius: 20px;
            background: var(--color-primary);


        }
    }

    .service-item {
        position: relative;
        z-index: 2;
        gap: 20px;
        overflow: hidden;

        .service-link {
            border: none;
            padding: 0;
            margin: 0;
            background: transparent;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        .expertise-card-logo {
            transition: all 0.4s ease;
        }

    }

    .number {
        color: var(--color-primary);
        font-size: 20px;
        /* 	font-weight: var(--tj-fw-bold); */
        -webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .active-bg {
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        position: absolute;
        z-index: 0;
        -webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
}



.service {
    &.service__style--1 {
        padding: 30px 26px;;
        padding-bottom: 30px;
        transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
        position: relative;
        z-index: 2;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
        }
        .shape-service-1{
            position: absolute;
            right: 15px;
            top: 15px;
            z-index: 1;
            max-width: 150px;
            margin: auto;
            opacity: 0;
        }
        .icon {
            transition: .3s;
            font-size: 45px;
            font-weight: 400;
            margin-bottom: 23px;
            color: var(--color-primary);
            text-align: center;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
            i {
                stroke-width: 1 !important;
                font-size: 60px;
            }
            svg {
                stroke-width: 2px !important;
                width: 45px;
                height: 45px;
            }
            img {
                max-height: 60px;
            }
        }
        .content {
            .title {
                margin-bottom: 19px;
                transition: .3s;
                @media #{$md-layout} {
                    margin-bottom: 8px;
                }
                a {
                    color: inherit;
                    transition: .3s;
                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
            p {
                transition: .3s;
            }
            .discover-btn {
                text-align: center;
                display: flex;
                justify-content: center;
            }
        }

        &:hover{
            transform: translateY(-5px);
            .shape-service-1{
                opacity: .3;
                img{
                    animation: moveOut .4s linear;
                }
            }
        }

        &.tmp-border-none {
            border: none;
        }

        &.tmp-border {
            border: 2px solid var(--color-border);
        }
        
        &.text-left,
        &.text-start {
          
            @media #{$laptop-device} {
                padding: 40px 30px;
            }
            @media #{$lg-layout} {
                padding: 40px 30px;
            }
            @media #{$md-layout} {
                padding: 40px 30px;
            }
            @media #{$sm-layout} {
                padding: 30px 20px;
            }
            .icon {
                justify-content: left;
            }
            .content {
                .discover-btn {
                    justify-content: flex-start;
                }
            }
        }

        &.icon-circle-style {
            background-color: transparent;
            box-shadow: none;
            padding: 0px 35px;
            margin-top: 25px;
            @media #{$laptop-device} {
                padding: 0px 10px;
            }
            @media #{$lg-layout} {
                padding: 0px 10px;
            }
            @media #{$md-layout} {
                padding: 0px 10px;
            }
            @media #{$sm-layout} {
                padding: 0px 10px;
            }
            .icon {
                font-size: 39px;
                color: var(--color-primary);
                display: flex;
                justify-content: center;
                width: 90px;
                height: 90px;
                margin: 0 auto;
                margin-bottom: 23px;
                background-color: var(--color-primary-darker);
                line-height: 90px;
                align-items: center;
            }
        }

        &.with-working-process {
            position: relative;
            @media #{$lg-layout} {
                padding: 20px 0;
            }
            .icon {
                width: 180px;
                height: 180px;
                line-height: 180px;
                font-size: 60px;
                position: relative;
            }
            .line {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 1px;
                width: 100%;
                left: 100%;
                background-color: var(--color-border);
                @media #{$md-layout} {
                    display: none;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
            &:hover {
                transform: none;
            }
        }


        &.variation-2 {
            padding-bottom: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &.variation-3 {
            padding-bottom: 50px;
        }

        &.service-bg-transparent {
            padding: 0;
            .icon {
                margin-bottom: 12px;
            }
            .content {
                .title {
                    margin-bottom: 12px;
                }
            }
        }

    }
}

.skill-style-border-card{
    border-radius: 5px;
    padding: 30px 0 18px 0;
    margin-bottom: 0;
    transition: 0.3s;
    height: 100%;
    text-align: center;
    img{
        display: block;
        margin: auto auto 20px auto;
        max-width: 50px;
        max-height: 50px;
    }
    .count-number{
        margin-bottom: 5px;
        font-size: 44px;
    }
}